import { WhmButton } from "@securitize/reactjs-whm";
import { useState } from "react";
import KeyPartyModal from "../../components/KeyPartyModal";

export default function KeyParties() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  return (
    <div>
      <WhmButton onClick={handleOpen}>Open modal</WhmButton>
      <KeyPartyModal open={open} onClose={handleClose} />
    </div>
  );
}
