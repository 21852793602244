import React from "react";
import parse, { domToReact, DOMNode, Element } from "html-react-parser";
import { sanitize } from "dompurify";
import styles from "./SanitizedReactHtmlParser.module.css";

const SanitizedReactHtmlParser = ({ content }: { content: string }) => {
  const options = {
    replace: (domNode: DOMNode) => {
      if ((domNode as Element).name === "span") {
        return (
          <span className={styles.styledParagraph}>
            {domToReact((domNode as Element).children as DOMNode[])}
          </span>
        );
      } else if (domNode instanceof Element) {
        return domToReact(domNode.children as DOMNode[]);
      }
      return domNode;
    },
  };

  return (
    <>
      {parse(
        sanitize(content, {
          ADD_TAGS: ["iframe"], // or ALLOWED_TAGS
          ADD_ATTR: [
            "allow",
            "allowfullscreen",
            "frameborder",
            "scrolling",
            "target",
          ], // or //or ALLOWED_ATR
        }),
        options,
      )}
    </>
  );
};

export default SanitizedReactHtmlParser;
