import { WhmBox, WhmContainer } from "@securitize/reactjs-whm";
import UserMenuContainer from "../../components/UserMenu";

export default function UserMenuView() {
  return (
    <WhmContainer>
      <WhmBox>
        <UserMenuContainer
          onCreateUser={() => null}
          onLogout={() => null}
          onSwitchUser={() => null}
        />
      </WhmBox>
    </WhmContainer>
  );
}
