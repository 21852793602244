const config = {
  SID_GW_BASE_URL: process.env.REACT_APP_SID_GW_BASE_URL || "null-ref",
  AUTH_LOGIN_URL:
    (process.env.REACT_APP_AUTH_BASE_URL || "null-ref") + "/login",
  AUTH_REGISTER_URL:
    (process.env.REACT_APP_AUTH_BASE_URL || "null-ref") +
    "/register?reg_data={reg_data}",
} as const;

export default config;
