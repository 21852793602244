import React, { useContext } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import { TranslationKey } from "../../contexts/I18nContext/translationKeys";
import SanitizedReactHtmlParser from "../../utils/SanitizedReactHtmlParser/SanitizedReactHtmlParser";

interface TranslateProps {
  translationKey: TranslationKey;
  replacements?: Record<string, string | number>;
}

export function Translate({
  translationKey,
  replacements,
}: TranslateProps): JSX.Element {
  const { getTranslation } = useContext(I18nContext);

  return <>{getTranslation(translationKey, replacements)}</>;
}

export const TranslateHtml = ({
  translationKey,
  parameters,
}: {
  translationKey: TranslationKey;
  parameters?: Record<string, string | number>;
}) => {
  const { getTranslation } = React.useContext(I18nContext);
  return (
    <SanitizedReactHtmlParser
      content={getTranslation(translationKey, parameters)}
    />
  );
};
