import {
  WhmSnackbar,
  WhmBox,
  useMediaQuery,
  Theme,
} from "@securitize/reactjs-whm";
import { Button, Container, Message, Wrapper, ButtonContainer } from "./styles";
import translationKeys from "../../../contexts/I18nContext/translationKeys";
import { Translate } from "../../Translation/Translate";
import React from "react";

export interface SnackBarState {
  message?: string;
  severity?: "error" | "warning" | "info" | "success";
  callback?: () => void;
  isOpen?: boolean;
}

interface SnackBarProps {
  isOpen: boolean;
  severity?: "error" | "warning" | "info" | "success";
  message?: string;
  onClose: (event: React.SyntheticEvent<any> | Event, reason: string) => void;
  callback?: () => void;
  showButton?: boolean;
}

const SnackBar = ({
  isOpen,
  severity,
  message,
  onClose,
  callback,
  showButton,
}: SnackBarProps) => {
  const isMobileOrTablet = useMediaQuery((theme: Theme) =>
    theme.breakpoints.down("xl"),
  );

  const isError = severity === "error";

  return (
    <WhmSnackbar
      open={isOpen}
      onClose={onClose}
      autoHideDuration={8000}
      anchorOrigin={{
        vertical: isMobileOrTablet ? "top" : "bottom",
        horizontal: "center",
      }}
    >
      <Container severity={severity || ""}>
        <Wrapper>
          <WhmBox>
            <Message>{message}</Message>
          </WhmBox>
          {showButton && (
            <ButtonContainer className={isError ? "error" : ""}>
              <Button
                variant="outlined"
                type="button"
                onClick={(e: {
                  stopPropagation: () => void;
                  preventDefault: () => void;
                }) => {
                  e.stopPropagation();
                  e.preventDefault();
                  callback && callback();
                }}
              >
                <Translate
                  translationKey={
                    translationKeys.SNACKBAR_GENERAL_ERROR_BUTTON_TEXT
                  }
                />
              </Button>
            </ButtonContainer>
          )}
        </Wrapper>
      </Container>
    </WhmSnackbar>
  );
};

export default SnackBar;
