import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import Cookies from "js-cookie";
import "./index.css";

const standaloneLogin = async () => {
  if (
    !(
      process.env.REACT_APP_STANDALONE_LOGIN_USERNAME &&
      process.env.REACT_APP_STANDALONE_LOGIN_PASSWORD
    )
  ) {
    return;
  }

  const request = await fetch("/gw/api-dsc/login", {
    headers: {
      "content-type": "application/json",
    },
    body: JSON.stringify({
      email: process.env.REACT_APP_STANDALONE_LOGIN_USERNAME!,
      password: process.env.REACT_APP_STANDALONE_LOGIN_PASSWORD!,
    }),
    method: "POST",
  });

  if (!request.ok) {
    throw new Error(`Failed to login ${await request.text()}`);
  }

  const response = (await request.json()) as { token: string };
  Cookies.set("auth-token", response.token);
};

standaloneLogin()
  .then(() => {
    const root = ReactDOM.createRoot(
      document.getElementById("root") as HTMLElement,
    );
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    );
  })
  .catch((e) => {
    console.error(e);
  });

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
