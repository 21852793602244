const translationKeys = {
  REGISTRATION_NEXT: "Texts.registration-next",
  REGISTRATION_ACCOUNT_EXISTS: "Texts.registration-account-exists",
  REGISTRATION_LOGIN: "Texts.registration-login",

  REGISTRATION_STEP_NAME_TITLE: "Texts.registration-step-name-title",
  REGISTRATION_STEP_NAME_SUBTITLE: "Texts.registration-step-name-subtitle",
  REGISTRATION_STEP_PHONE_TITLE: "Texts.registration-step-phone-title",
  REGISTRATION_STEP_PHONE_SUBTITLE: "Texts.registration-step-phone-subtitle",
  REGISTRATION_STEP_TYPE_TITLE: "Texts.registration-step-type-title",
  REGISTRATION_STEP_ENTITY_TITLE: "Texts.registration-step-entity-title",

  REGISTRATION_FIRST_NAME: "Texts.registration-first-name",
  REGISTRATION_LAST_NAME: "Texts.registration-last-name",

  REGISTRATION_RESIDENCE_COUNTRY: "Texts.registration-residence-country",
  REGISTRATION_RESIDENCE_STATE: "Texts.registration-residence-state",
  REGISTRATION_PHONE: "Texts.registration-phone",

  INDIVIDUAL: "Texts.individual",
  ENTITY: "Texts.entity",
  INDIVIDUAL_DESCRIPTION: "Texts.individual-description",
  ENTITY_DESCRIPTION: "Texts.entity-description",

  REGISTRATION_ENTITY_NAME: "Texts.registration-entity-name",
  REGISTRATION_ENTITY_COUNTRY: "Texts.registration-entity-country",
  REGISTRATION_ENTITY_STATE: "Texts.registration-entity-state",

  REGISTRATION_VALIDATION_REQUIRED_FIRST_NAME:
    "Texts.registration-validation-required-first-name",
  REGISTRATION_VALIDATION_INVALID_NAME:
    "Texts.registration-validation-invalid-name",
  REGISTRATION_VALIDATION_REQUIRED_LAST_NAME:
    "Texts.registration-validation-required-last-name",
  REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_COUNTRY:
    "Texts.registration-validation-required-residence-country",
  REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_STATE:
    "Texts.registration-validation-required-residence-state",
  REGISTRATION_VALIDATION_REQUIRED_PHONE:
    "Texts.registration-validation-required-phone",
  REGISTRATION_VALIDATION_INVALID_PHONE:
    "Texts.registration-validation-invalid-phone",
  REGISTRATION_VALIDATION_REQUIRED_ACCOUNT_TYPE:
    "Texts.registration-validation-required-account-type",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_NAME:
    "Texts.registration-validation-required-entity-name",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_COUNTRY:
    "Texts.registration-validation-required-entity-country",
  REGISTRATION_VALIDATION_REQUIRED_ENTITY_STATE:
    "Texts.registration-validation-required-entity-state",
  REGISTRATION_VALIDATION_MINIMUM_LENGTH:
    "Texts.registration-validation-minimum-length",
  REGISTRATION_VALIDATION_MAXIMUM_LENGTH:
    "Texts.registration-validation-maximum-length",

  // Phone Verification Modal
  PHONE_VERIFICATION_TITLE: "Texts.phone-verification-modal-title",
  PHONE_VERIFICATION_PHONE_STEP_SUBTITLE:
    "Texts.phone-verification-modal-phone-step-subtitle",
  PHONE_VERIFICATION_PHONE_STEP_FOOTER:
    "Texts.phone-verification-modal-phone-step-footer",
  PHONE_VERIFICATION_PHONE_STEP_CTA_BUTTON:
    "Texts.phone-verification-modal-phone-step-cta-button",
  PHONE_VERIFICATION_CODE_STEP_SUBTITLE:
    "Texts.phone-verification-modal-code-step-subtitle",
  PHONE_VERIFICATION_CODE_STEP_FOOTER:
    "Texts.phone-verification-modal-code-step-footer",
  PHONE_VERIFICATION_CODE_STEP_CTA_BUTTON:
    "Texts.phone-verification-modal-code-step-cta-button",
  PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST:
    "Texts.phone-verification-modal-code-step-new-code-request",
  PHONE_VERIFICATION_CODE_STEP_NEW_CODE_REQUEST_BUTTON:
    "Texts.phone-verification-modal-code-step-new-code-request-button",
  PHONE_VERIFICATION_CODE_STEP_CODE_RESENT:
    "Texts.phone-verification-modal-code-step-new-code-resent",
  PHONE_VERIFICATION_PHONE_INPUT_LABEL:
    "Texts.phone-verification-modal-phone-input-label",
  PHONE_VERIFICATION_PHONE_INPUT_EMPTY_HELPER_TEXT:
    "Texts.phone-verification-modal-phone-input-empty-helper-text",
  PHONE_VERIFICATION_PHONE_INPUT_INVALID_HELPER_TEXT:
    "Texts.phone-verification-modal-phone-input-invalid-helper-text",
  PHONE_VERIFICATION_CODE_INPUT_EMPTY_HELPER_TEXT:
    "Texts.phone-verification-modal-code-input-empty-helper-text",
  PHONE_VERIFICATION_CODE_INPUT_INVALID_HELPER_TEXT:
    "Texts.phone-verification-modal-code-input-invalid-helper-text",
  PHONE_VERIFICATION_NUMBER_VERIFIED:
    "Texts.phone-verification-modal-number-verified",
  PHONE_VERIFICATION_ERROR_GENERATING_CODE:
    "Texts.phone-verification-modal-error-generating-code",

  // Snackbar
  SNACKBAR_GENERAL_ERROR_BUTTON_TEXT:
    "Texts.snackbar-general-error-button-text",

  // User Menu
  USER_MENU_INVESTOR_STATUS_VERIFIED:
    "Texts.user-menu-investor-status-verified",
  USER_MENU_INVESTOR_STATUS_PENDING: "Texts.user-menu-investor-status-pending",
  USER_MENU_INVESTOR_STATUS_DOCUMENTS_EXPIRED:
    "Texts.user-menu-investor-status-documents-expired",
  USER_MENU_INVESTOR_STATUS_BLOCKED: "Texts.user-menu-investor-status-blocked",
  USER_MENU_INVESTOR_STATUS_UPDATES_REQUIRED:
    "Texts.user-menu-investor-status-updates-required",
  USER_MENU_INVESTOR_STATUS_NOT_VERIFIED:
    "Texts.user-menu-investor-status-not-verified",
  USER_MENU_INVESTOR_STATUS_EXPIRED: "Texts.user-menu-investor-status-expired",
  USER_MENU_INVESTOR_STATUS_SERVICE_DENIED:
    "Texts.user-menu-investor-status-service-denied",

  USER_MENU_CREATE_ENTITY_ACCOUNT: "Texts.user-menu-create-entity-account",

  USER_MENU_ERROR_LOADING_INVESTORS: "Texts.user-menu-error-loading-investors",
  USER_MENU_LOADING_INVESTORS: "Texts.user-menu-loading-investors",

  LOGOUT: "Texts.logout",
} as const;

export default translationKeys;

export type TranslationKey =
  (typeof translationKeys)[keyof typeof translationKeys];
