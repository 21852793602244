import {
  WhmAutocomplete,
  WhmBox,
  WhmButton,
  WhmFade,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import secLogoPositive from "@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-positive.svg";
import { useFormikContext } from "formik";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { getCountriesList, statesUS } from "./countriesList";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import { Translate } from "../Translation/Translate";
import { FormType } from "./types";
import React from "react";

export default function EntityInfoStep({
  resizeOnChange,
  filler,
}: {
  resizeOnChange?: () => void;
  filler?: React.ReactNode;
}) {
  const formik = useFormikContext<FormType>();

  return (
    <WhmStack sx={{ height: "100%" }} spacing={6} alignItems="center">
      <WhmBox
        component="img"
        sx={{ height: "24px" }}
        src={secLogoPositive}
        alt="Securitize Logo"
      />
      <WhmStack alignItems="center" sx={{ marginBottom: "8px !important" }}>
        <WhmTypography variant="h4" align="center">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_ENTITY_TITLE}
          />
        </WhmTypography>
      </WhmStack>
      <WhmStack spacing={4} alignItems="center" sx={{ width: "100%" }}>
        <WhmTextField
          label={
            <Translate
              translationKey={TranslationKeys.REGISTRATION_ENTITY_NAME}
            />
          }
          fullWidth
          name="entityName"
          value={formik.values.entityName}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.entityName && Boolean(formik.errors.entityName)}
          helperText={formik.touched.entityName && formik.errors.entityName}
        />
        <WhmAutocomplete
          fullWidth
          value={formik.values.entityCountry}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          onChange={(e, v) => {
            void formik.setFieldValue("entityCountry", v);
          }}
          onBlur={() => {
            void formik.setFieldTouched("entityCountry");
          }}
          options={getCountriesList("en")}
          renderInput={(params) => (
            <WhmTextField
              {...params}
              name="entityCountry"
              error={
                formik.touched.entityCountry &&
                Boolean(formik.errors.entityCountry)
              }
              helperText={
                formik.touched.entityCountry && formik.errors.entityCountry
              }
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <WhmBox sx={{ marginRight: "0.5em", fontSize: "24px" }}>
                    {formik.values.entityCountry
                      ? getUnicodeFlagIcon(formik.values.entityCountry.value)
                      : null}
                  </WhmBox>
                ),
              }}
              label={
                <Translate
                  translationKey={TranslationKeys.REGISTRATION_ENTITY_COUNTRY}
                />
              }
            />
          )}
        />
        <WhmFade
          in={formik.values.entityCountry?.value === "US"}
          mountOnEnter
          unmountOnExit
          onEnter={resizeOnChange}
          onExited={() => {
            resizeOnChange && requestAnimationFrame(resizeOnChange);
          }}
        >
          <WhmAutocomplete
            options={statesUS}
            onBlur={() => void formik.setFieldTouched("entityState")}
            onChange={(e, v) => void formik.setFieldValue("entityState", v)}
            value={formik.values.entityState}
            fullWidth
            renderInput={(props) => (
              <WhmTextField
                {...props}
                label={
                  <Translate
                    translationKey={TranslationKeys.REGISTRATION_ENTITY_STATE}
                  />
                }
                name="state"
                error={
                  formik.touched.entityState &&
                  Boolean(formik.errors.entityState)
                }
                helperText={
                  formik.touched.entityState && formik.errors.entityState
                }
              />
            )}
          />
        </WhmFade>
      </WhmStack>
      {filler}
      <WhmButton
        variant="contained"
        size="large"
        onClick={() => void formik.submitForm()}
        fullWidth
      >
        <Translate translationKey={TranslationKeys.REGISTRATION_NEXT} />
      </WhmButton>
    </WhmStack>
  );
}
