import {
  WhmBox,
  WhmButton,
  WhmTypography,
  styled,
  whmTheme,
} from "@securitize/reactjs-whm";

interface ContainerProps {
  severity: string;
}

const handleColor = (severity: string) => {
  switch (severity) {
    case "error":
      return whmTheme.palette.error.main;
    case "warning":
      return whmTheme.palette.warning.main;
    case "info":
      return whmTheme.palette.info.main;
    case "success":
      return whmTheme.palette.secondary.main;
    default:
      return whmTheme.palette.error.main;
  }
};

export const Container = styled(WhmBox)<ContainerProps>(({ severity }) => ({
  borderRadius: "6px",
  paddingTop: "14px",
  paddingBottom: "14px",
  paddingRight: "16px",
  paddingLeft: "16px",
  width: "auto",
  backgroundColor: handleColor(severity),
}));

export const ButtonContainer = styled(WhmBox)(() => ({
  visibility: "hidden",
  display: "none",
  "&.error": {
    visibility: "visible",
    display: "block",
  },
}));

export const Wrapper = styled(WhmBox)(() => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

export const Message = styled(WhmTypography)(({ theme }) => ({
  width: "auto",
  [theme.breakpoints.down("xl")]: {
    width: "90%",
  },
  fontWeight: 400,
  color: whmTheme.palette.common.white,
  fontSize: "14px",
  lineHeight: "20px",
}));

export const Button = styled(WhmButton)(() => ({
  borderColor: whmTheme.palette.common.white,
  color: whmTheme.palette.common.white,
  padding: "5px 12px",
  maxWidth: "90px",
  maxHeight: "36px",
  textTransform: "none",
  fontSize: "14px",
  lineHeight: "24px",
  fontWeight: 500,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  ":hover": {
    borderColor: whmTheme.palette.common.white,
    color: whmTheme.palette.common.white,
  },
}));
