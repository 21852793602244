import { WhmButton } from "@securitize/reactjs-whm";
import { useState } from "react";
import PhoneVerificationModal from "../../components/PhoneVerificationModal";

export default function PhoneVerification() {
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);

  const onVerify = () => {
    console.log("Phone verified");
  };

  return (
    <div>
      <WhmButton onClick={handleOpen}>Open Phone Verification modal</WhmButton>
      <PhoneVerificationModal
        onVerify={onVerify}
        phone={{ code: "+1", number: "2013345566", fullNumber: "+12013345566" }}
        countryCode={"us"}
        open={open}
      />
    </div>
  );
}
