import { Phone, PhoneValidationStep } from "./types";
import { useCallback, useState } from "react";
import { Theme, useMediaQuery, WhmDialog } from "@securitize/reactjs-whm";
import PhoneStep from "./PhoneStep";
import CodeStep from "./CodeStep";
import {
  useGenerateCodeMutation,
  useValidateCodeMutation,
} from "../../data/usePhoneVerification";
import SnackBar, { SnackBarState } from "../Shared/Snackbar/snackbar";

export interface PhoneVerificationModalProps {
  countryCode: string;
  phone?: Phone;
  onVerify: () => void;
  open: boolean;
}

export default function PhoneVerificationModal({
  countryCode,
  phone,
  onVerify,
  open,
}: PhoneVerificationModalProps) {
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("lg"));
  const tablet = useMediaQuery((theme: Theme) => theme.breakpoints.down("xl"));
  const mobileOrTablet = mobile || tablet;

  const [step, setStep] = useState<PhoneValidationStep>("phone");
  const [phoneState, setPhoneState] = useState<Phone>(
    phone || { code: "", number: "", fullNumber: "" },
  );
  const [identifier, setIdentifier] = useState<string>("");

  const generateCode = useGenerateCodeMutation();
  const validateCode = useValidateCodeMutation();

  const onCloseOverride = (_: Object, reason: string) => {
    if (reason !== "backdropClick") {
      // Do not close
    }
  };

  const [snackBarState, setSnackBarState] = useState<SnackBarState>({
    isOpen: false,
    severity: "info",
  });

  const hideSnackBar = (
    event: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setSnackBarState({
      isOpen: false,
      message: snackBarState.message,
      severity: snackBarState.severity,
    });
  };

  const showSnackBar = useCallback(
    ({ callback, message, severity, isOpen = true }: SnackBarState) => {
      setSnackBarState({
        isOpen,
        callback,
        message,
        severity,
      });
    },
    [setSnackBarState],
  );

  return (
    /* This ref is used to mount the modal in this same dom tree
       Using it as a container for the WhmDialog, we avoid the modal to be loaded in its own root component. */
    <div>
      <WhmDialog
        disablePortal={true}
        open={open}
        onClose={onCloseOverride}
        maxWidth={false}
        fullScreen={mobileOrTablet}
      >
        <div
          style={{
            display: step === "phone" ? "block" : "none",
            height: "100%",
          }}
        >
          <PhoneStep
            phone={phone}
            countryCode={countryCode}
            setStep={setStep}
            setPhone={setPhoneState}
            setIdentifier={setIdentifier}
            generateCode={generateCode}
            showSnackBar={showSnackBar}
          />
        </div>
        {step === "code" && (
          <CodeStep
            setStep={setStep}
            onVerify={onVerify}
            generateCode={generateCode}
            phone={phoneState}
            identifier={identifier}
            validateCode={validateCode}
            showSnackBar={showSnackBar}
          />
        )}
      </WhmDialog>

      <SnackBar
        isOpen={!!snackBarState.isOpen}
        onClose={hideSnackBar}
        message={snackBarState.message}
        severity={snackBarState.severity}
        callback={() => snackBarState.callback?.()}
      />
    </div>
  );
}
