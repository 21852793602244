import { useMutation } from "@tanstack/react-query";
import config from "./config";
import axios from "axios";
import Cookies from "js-cookie";
import { Phone } from "../components/PhoneVerificationModal/types";

export const useGenerateCodeMutation = () => {
  return useMutation({
    mutationFn: async (phone: { phone: Phone }) => {
      const { data } = await axios.post<{ identifier: string }>(
        `${config.SID_GW_BASE_URL}/v1/investor/otp/generate`,
        phone,
        {
          withCredentials: true,
          headers: {
            Authorization: Cookies.get("auth-token"),
          },
        },
      );
      return data;
    },
  });
};

export const useValidateCodeMutation = () => {
  return useMutation({
    mutationFn: async (data: {
      phone: Phone;
      code: string;
      identifier: string;
    }) => {
      await axios.post<{}>(
        `${config.SID_GW_BASE_URL}/v1/investor/otp/verify`,
        data,
        {
          withCredentials: true,
          headers: {
            Authorization: Cookies.get("auth-token"),
          },
        },
      );
    },
  });
};
