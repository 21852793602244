import {
  WhmAutocomplete,
  WhmBox,
  WhmButton,
  WhmFade,
  WhmStack,
  WhmTextField,
  WhmTypography,
} from "@securitize/reactjs-whm";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils";
import secLogoPositive from "@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-positive.svg";
import { useFormikContext } from "formik";
import getUnicodeFlagIcon from "country-flag-icons/unicode";
import { getCountriesList, statesUS } from "./countriesList";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import { Translate } from "../Translation/Translate";
import { FormType } from "./types";
import { getDialCodeByCountryCode } from "../../helpers/phoneHelper";

export default function PhoneStep({
  filler,
  resizeOnChange,
}: {
  filler?: React.ReactNode;
  resizeOnChange?: () => void;
}) {
  const formik = useFormikContext<FormType>();
  return (
    <WhmStack sx={{ height: "100%" }} spacing={6} alignItems="center">
      <WhmBox
        component="img"
        sx={{ height: "24px" }}
        src={secLogoPositive}
        alt="Securitize Logo"
      />
      <WhmStack alignItems="center" sx={{ marginBottom: "8px !important" }}>
        <WhmTypography variant="h4" align="center">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_PHONE_TITLE}
          />
        </WhmTypography>
        <WhmTypography variant="body2" color="GrayText">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_PHONE_SUBTITLE}
          />
        </WhmTypography>
      </WhmStack>
      <WhmStack spacing={4} alignItems="center" sx={{ width: "100%" }}>
        <WhmAutocomplete
          fullWidth
          value={formik.values.country}
          isOptionEqualToValue={(option, value) => option.value === value.value}
          getOptionLabel={(option) => option.label}
          onChange={(e, v) => {
            void formik.setFieldValue("country", v);
          }}
          onBlur={() => {
            void formik.setFieldTouched("country");
          }}
          options={getCountriesList("en")}
          renderInput={(params) => (
            <WhmTextField
              {...params}
              name="countryCode"
              error={formik.touched.country && Boolean(formik.errors.country)}
              helperText={formik.touched.country && formik.errors.country}
              InputProps={{
                ...params.InputProps,
                startAdornment: (
                  <WhmBox sx={{ marginRight: "0.5em", fontSize: "24px" }}>
                    {formik.values.country
                      ? getUnicodeFlagIcon(formik.values.country.value)
                      : null}
                  </WhmBox>
                ),
              }}
              label={
                <Translate
                  translationKey={
                    TranslationKeys.REGISTRATION_RESIDENCE_COUNTRY
                  }
                />
              }
            />
          )}
        />
        <WhmFade
          in={formik.values.country?.value === "US"}
          mountOnEnter
          unmountOnExit
          onEnter={resizeOnChange}
          onExited={() => {
            resizeOnChange && requestAnimationFrame(resizeOnChange);
          }}
        >
          <WhmAutocomplete
            options={statesUS}
            onBlur={() => void formik.setFieldTouched("state")}
            onChange={(e, v) => void formik.setFieldValue("state", v)}
            value={formik.values.state}
            fullWidth
            renderInput={(props) => (
              <WhmTextField
                {...props}
                label={
                  <Translate
                    translationKey={
                      TranslationKeys.REGISTRATION_RESIDENCE_STATE
                    }
                  />
                }
                name="state"
                error={formik.touched.state && Boolean(formik.errors.state)}
                helperText={formik.touched.state && formik.errors.state}
              />
            )}
          />
        </WhmFade>
        <WhmTextField
          label={
            <Translate translationKey={TranslationKeys.REGISTRATION_PHONE} />
          }
          type="tel"
          fullWidth
          name="phone"
          value={formik.values.phone}
          inputProps={{ style: { width: "100%" } }}
          placeholder={
            formik.values.country
              ? intlTelInput.utils!.getExampleNumber(
                  formik.values.country.value,
                  true,
                  2,
                )
              : undefined
          }
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
          InputProps={{
            startAdornment: (
              <WhmBox sx={{ marginRight: "0.5em" }}>
                +
                {formik.values.country
                  ? getDialCodeByCountryCode(formik.values.country.value)
                  : null}
              </WhmBox>
            ),
          }}
        />
      </WhmStack>
      {filler}
      <WhmButton
        variant="contained"
        size="large"
        onClick={() => void formik.submitForm()}
        fullWidth
      >
        <Translate translationKey={TranslationKeys.REGISTRATION_NEXT} />
      </WhmButton>
    </WhmStack>
  );
}
