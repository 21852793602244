import { useQuery } from "@tanstack/react-query";
import config from "./config";

const useTranslationQuery = (language: string) =>
  useQuery({
    queryKey: ["translations", language],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/translations/${language}`,
      );

      if (!response.ok) {
        throw new Error(`Failed to fetch translations`);
      }

      return response.json() as Promise<Record<string, string>>;
    },
    // We need to do this because we don't want to refetch the translations every time we change the language
    staleTime: Infinity,
  });

export default useTranslationQuery;
