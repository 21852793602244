import { useQuery } from "@tanstack/react-query";
import Cookies from "js-cookie";
import config from "./config";

export const CalculatedVerificationStatuses = {
  NOT_VERIFIED: "not-verified",
  PENDING: "pending",
  DOCUMENTS_EXPIRED: "documents-expired",
  BLOCKED: "blocked",
  UPDATES_REQUIRED: "updates-required",
  VERIFIED: "verified",
  EXPIRED: "expired",
  SERVICE_DENIED: "service-denied",
} as const;

export type CalculatedVerificationStatus =
  (typeof CalculatedVerificationStatuses)[keyof typeof CalculatedVerificationStatuses];

export type UserInvestorsResponse = {
  currentInvestor: string;
  availableInvestors: {
    id: string;
    displayName: string;
    verification: {
      calculatedStatus: CalculatedVerificationStatus;
    };
    investorType: "individual" | "entity";
  }[];
};

const useUserInvestorsQuery = () =>
  useQuery({
    queryKey: ["user-investors"],
    queryFn: async () => {
      const response = await fetch(
        `${config.SID_GW_BASE_URL}/v1/investor/availableInvestors?include=investors`,
        {
          credentials: "include",
          headers: {
            Authorization: Cookies.get("auth-token") ?? "",
          },
        },
      );
      if (!response.ok) {
        console.error(await response.text());
        throw new Error("Error fetching investors");
      }
      const result = await (response.json() as Promise<UserInvestorsResponse>);

      result.availableInvestors.sort((a, b) => {
        if (a.investorType === "individual") {
          return -1;
        } else if (b.investorType === "individual") {
          return 1;
        }

        return a.displayName.localeCompare(b.displayName);
      });

      const currentInvestorData = result.availableInvestors.find(
        (investor) => investor.id === result.currentInvestor,
      )!;

      return {
        currentInvestorData,
        ...result,
      };
    },
  });

export default useUserInvestorsQuery;
