import {
  WhmAvatar,
  WhmBox,
  WhmButton,
  WhmList,
  WhmListItem,
  WhmListItemAvatar,
  WhmListItemButton,
  WhmStack,
  WhmTypography,
} from "@securitize/reactjs-whm";
import secLogoPositive from "@securitize/assets-sec-ui/dist/logos/svg/sec-trademark-positive.svg";
import { useFormikContext } from "formik";
import TranslationKeys from "../../contexts/I18nContext/translationKeys";
import { Translate } from "../Translation/Translate";
import { FormType } from "./types";
import investor from "@securitize/assets-sec-ui/dist/icons/svg/individual-profile.svg";
import entity from "@securitize/assets-sec-ui/dist/icons/svg/entity.svg";

export default function AccountTypeStep({
  filler,
}: {
  filler?: React.ReactNode;
}) {
  const formik = useFormikContext<FormType>();

  return (
    <WhmStack sx={{ height: "100%" }} spacing={6} alignItems="center">
      <WhmBox
        component="img"
        sx={{ height: "24px" }}
        src={secLogoPositive}
        alt="Securitize Logo"
      />
      <WhmStack alignItems="center" sx={{ marginBottom: "8px !important" }}>
        <WhmTypography variant="h4" align="center">
          <Translate
            translationKey={TranslationKeys.REGISTRATION_STEP_TYPE_TITLE}
          />
        </WhmTypography>
      </WhmStack>
      <WhmStack sx={{ width: "100%" }}>
        {formik.touched.investorType && formik.errors.investorType && (
          <WhmTypography variant="body2" color="error">
            {formik.errors.investorType}
          </WhmTypography>
        )}
        <WhmList sx={{ width: "100%" }}>
          <WhmListItem sx={{ padding: "8px 0" }}>
            <WhmListItemButton
              sx={{
                border:
                  formik.values.investorType === "individual"
                    ? "2px solid black"
                    : "1px solid rgba(0, 0, 0, 0.09)",
                padding:
                  formik.values.investorType === "individual" ? "15px" : "16px",
                borderRadius: "4px",
              }}
              onClick={() => {
                void formik.setFieldTouched("investorType");
                void formik.setFieldValue("investorType", "individual");
              }}
            >
              <WhmListItemAvatar>
                <WhmAvatar sx={{ backgroundColor: "#e0e0e0" }}>
                  <WhmBox
                    sx={{ height: "24px", width: "24px" }}
                    component="img"
                    src={investor}
                  />
                </WhmAvatar>
              </WhmListItemAvatar>
              <WhmStack>
                <WhmTypography variant="body2">
                  <Translate translationKey={TranslationKeys.INDIVIDUAL} />
                </WhmTypography>
                <WhmTypography variant="body2" color="GrayText">
                  <Translate
                    translationKey={TranslationKeys.INDIVIDUAL_DESCRIPTION}
                  />
                </WhmTypography>
              </WhmStack>
            </WhmListItemButton>
          </WhmListItem>
          <WhmListItem sx={{ padding: "8px 0" }}>
            <WhmListItemButton
              sx={{
                border:
                  formik.values.investorType === "entity"
                    ? "2px solid black"
                    : "1px solid rgba(0, 0, 0, 0.09)",
                padding:
                  formik.values.investorType === "entity" ? "15px" : "16px",
                borderRadius: "4px",
              }}
              onClick={() => {
                void formik.setFieldTouched("investorType");
                void formik.setFieldValue("investorType", "entity");
              }}
            >
              <WhmListItemAvatar>
                <WhmAvatar sx={{ backgroundColor: "#e0e0e0" }}>
                  <WhmBox
                    sx={{ height: "24px", width: "24px" }}
                    component="img"
                    src={entity}
                  />
                </WhmAvatar>
              </WhmListItemAvatar>
              <WhmStack>
                <WhmTypography variant="body2">
                  <Translate translationKey={TranslationKeys.ENTITY} />
                </WhmTypography>
                <WhmTypography variant="body2" color="GrayText">
                  <Translate
                    translationKey={TranslationKeys.ENTITY_DESCRIPTION}
                  />
                </WhmTypography>
              </WhmStack>
            </WhmListItemButton>
          </WhmListItem>
        </WhmList>
      </WhmStack>
      {filler}
      <WhmButton
        variant="contained"
        size="large"
        onClick={() => void formik.submitForm()}
        fullWidth
      >
        <Translate translationKey={TranslationKeys.REGISTRATION_NEXT} />
      </WhmButton>
    </WhmStack>
  );
}
