import { WhmDialog } from "@securitize/reactjs-whm";

export interface KeyPartyModalProps {
  open: boolean;
  onClose: () => void;
}

export default function KeyPartyModal({ open, onClose }: KeyPartyModalProps) {
  return (
    <WhmDialog open={open} onClose={onClose}>
      Key Party Modal
    </WhmDialog>
  );
}
