import { WhmBox, WhmFade } from "@securitize/reactjs-whm";
import NameStep from "./NameStep";
import PhoneStep from "./PhoneStep";
import AccountTypeStep from "./AccountTypeStep";
import EntityInfoStep from "./EntityInfoStep";

const StepContainer = ({
  isOpen,
  children,
}: {
  isOpen: boolean;
  children: React.ReactNode;
}) => {
  return (
    <WhmFade in={isOpen} timeout={isOpen ? 1500 : 500}>
      <WhmBox
        sx={{
          position: "absolute",
          top: "0",
          left: "0",
          height: "100vh",
          width: "100vw",
          padding: "64px 16px 16px 16px",
        }}
      >
        {children}
      </WhmBox>
    </WhmFade>
  );
};

export default function MobileForm({ step }: { step: string }) {
  return (
    <WhmBox>
      <StepContainer isOpen={step === "name"}>
        <NameStep filler={<WhmBox sx={{ flexGrow: 1 }} />} />
      </StepContainer>

      <StepContainer isOpen={step === "phone"}>
        <PhoneStep filler={<WhmBox sx={{ flexGrow: 1 }} />} />
      </StepContainer>

      <StepContainer isOpen={step === "accountType"}>
        <AccountTypeStep filler={<WhmBox sx={{ flexGrow: 1 }} />} />
      </StepContainer>

      <StepContainer isOpen={step === "entityInfo"}>
        <EntityInfoStep filler={<WhmBox sx={{ flexGrow: 1 }} />} />
      </StepContainer>
    </WhmBox>
  );
}
