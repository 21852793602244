import { Theme, useMediaQuery } from "@securitize/reactjs-whm";
import intlTelInput from "intl-tel-input/intlTelInputWithUtils"; // TODO: We only use the Utils, find a way to import only that.
import { Formik, FormikHelpers } from "formik";
import * as Yup from "yup";
import { FormType, AuthRegistrationDataDto, RegistrationStep } from "./types";
import MobileForm from "./MobileForm";
import DesktopForm from "./DesktopForm";
import { useContext, useMemo, useState } from "react";
import { I18nContext } from "../../contexts/I18nContext";
import translationKeys from "../../contexts/I18nContext/translationKeys";
import config from "../../data/config";
import { SID } from "@securitize/domain";
import { getDialCodeByCountryCode } from "../../helpers/phoneHelper";

const latinRegex =
  // eslint-disable-next-line max-len
  /^[A-Za-záéíóúñàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðÐÞĞŞİ'-]+([ -][A-Za-záéíóúñàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ðÐÞĞŞİ'-]+)*$/;

const stepFields = {
  name: ["firstName", "lastName"],
  phone: ["country", "state", "phone"],
  accountType: ["investorType"],
  entityInfo: ["entityName", "entityCountry", "entityState"],
};
const useValidationStep = () => {
  const { getTranslation } = useContext(I18nContext);

  return useMemo(
    () => ({
      name: Yup.object().shape({
        firstName: Yup.string()
          .required(
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_REQUIRED_FIRST_NAME,
            ),
          )
          .matches(
            latinRegex,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_INVALID_NAME,
            ),
          )
          .min(
            2,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MINIMUM_LENGTH,
            ),
          )
          .max(
            32,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MAXIMUM_LENGTH,
            ),
          ),
        lastName: Yup.string()
          .required(
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_REQUIRED_LAST_NAME,
            ),
          )
          .matches(
            latinRegex,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_INVALID_NAME,
            ),
          )
          .min(
            2,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MINIMUM_LENGTH,
            ),
          )
          .max(
            32,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MAXIMUM_LENGTH,
            ),
          ),
      }),
      phone: Yup.object().shape({
        country: Yup.object().required(
          getTranslation(
            translationKeys.REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_COUNTRY,
          ),
        ),
        state: Yup.object().when("country", {
          is: (val: { value: string } | null) => val?.value === "US",
          then: (schema) =>
            schema.required(
              getTranslation(
                translationKeys.REGISTRATION_VALIDATION_REQUIRED_RESIDENCE_STATE,
              ),
            ),
          otherwise: (schema) => schema.nullable(),
        }),
        phone: Yup.string()
          .required(
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_REQUIRED_PHONE,
            ),
          )
          .test(
            "isValidPhone",
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_INVALID_PHONE,
            ),
            function (value) {
              return intlTelInput.utils!.isValidNumber(
                value,
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                this.parent.country?.value as string,
              );
            },
          ),
      }),
      accountType: Yup.object().shape({
        investorType: Yup.string().required(
          getTranslation(
            translationKeys.REGISTRATION_VALIDATION_REQUIRED_ACCOUNT_TYPE,
          ),
        ),
      }),
      entityInfo: Yup.object().shape({
        entityName: Yup.string()
          .required(
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_REQUIRED_ENTITY_NAME,
            ),
          )
          .matches(
            latinRegex,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_INVALID_NAME,
            ),
          )
          .min(
            2,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MINIMUM_LENGTH,
            ),
          )
          .max(
            32,
            getTranslation(
              translationKeys.REGISTRATION_VALIDATION_MAXIMUM_LENGTH,
            ),
          ),
        entityCountry: Yup.object().required(
          getTranslation(
            translationKeys.REGISTRATION_VALIDATION_REQUIRED_ENTITY_COUNTRY,
          ),
        ),
        entityState: Yup.object().when("entityCountry", {
          is: (val: { value: string } | null) => val?.value === "US",
          then: (schema) =>
            schema.required(
              getTranslation(
                translationKeys.REGISTRATION_VALIDATION_REQUIRED_ENTITY_STATE,
              ),
            ),
          otherwise: (schema) => schema.nullable(),
        }),
      }),
    }),
    [getTranslation],
  );
};

export default function Registration() {
  const { language } = useContext(I18nContext);
  const [step, setStep] = useState<RegistrationStep>("name");
  const mobile = useMediaQuery((theme: Theme) => theme.breakpoints.down("md"));
  const validationSchema = useValidationStep()[step];

  const goToStep = (
    newStep: RegistrationStep,
    formik: FormikHelpers<FormType>,
  ) => {
    for (const field of stepFields[newStep]) {
      void formik.setFieldTouched(field, false);
    }
    setStep(newStep);
  };

  return (
    <Formik<FormType>
      initialValues={{
        firstName: "",
        lastName: "",
        country: null,
        state: null,
        phone: "",
        entityName: "",
        entityCountry: null,
        entityState: null,
        investorType: "",
      }}
      validationSchema={validationSchema}
      onSubmit={(values, formik) => {
        if (step === "name") {
          goToStep("phone", formik);
          return;
        } else if (step === "phone") {
          goToStep("accountType", formik);
          return;
        } else if (step === "accountType" && values.investorType === "entity") {
          goToStep("entityInfo", formik);
          return;
        }

        const dialCode = `+${
          getDialCodeByCountryCode(values.country!.value) || ""
        }`;

        const data: AuthRegistrationDataDto = {
          firstName: values.firstName,
          lastName: values.lastName,
          phone: {
            code: dialCode,
            number: values.phone,
            fullNumber: `${dialCode}${values.phone}`,
          },
          investorData: {
            countryCode: values.country!.value,
            state: values.state?.value || undefined,
            analyticsEnabled: false, // Hardcoded to false
            investorType: values.investorType as SID.InvestorType,
            language: language,
            entityDetails: values.entityName
              ? {
                  entityName: values.entityName,
                  countryCode: values.entityCountry!.value,
                  state: values.entityState?.value,
                }
              : undefined,
          },
        };

        const encoded = btoa(JSON.stringify(data));

        const nextStepUrl = config.AUTH_REGISTER_URL.replace(
          "{reg_data}",
          encoded,
        );
        console.log(nextStepUrl);
        window.location.assign(nextStepUrl);
      }}
    >
      {mobile ? <MobileForm step={step} /> : <DesktopForm step={step} />}
    </Formik>
  );
}
