import React, { useCallback, useMemo } from "react";
import useTranslationQuery from "../../data/useTranslationsQuery";
import { TranslationKey } from "./translationKeys";

interface I18NContextType {
  language: string;
  getTranslation: (
    key: TranslationKey,
    replacements?: Record<string, string | number>,
  ) => string;
}

export interface I18NContextProviderType {
  language: string;
  children: React.ReactNode;
  loadingComponent?: React.ReactNode;
}

export const I18nContext = React.createContext<I18NContextType>(
  {} as I18NContextType,
);

export const I18nContextProvider: React.FC<I18NContextProviderType> = ({
  children,
  language,
  loadingComponent,
}) => {
  const translationQuery = useTranslationQuery(language);
  const getTranslation = useCallback(
    (
      translationKey: TranslationKey,
      replacements?: Record<string, string | number>,
    ) => {
      const translations = translationQuery.data ?? {};
      const translationText = translations[translationKey];

      if (!translationText) {
        return translationKey;
      }

      if (!replacements) {
        return translationText;
      }

      return Object.entries(replacements).reduce(
        (acc, [key, value]) =>
          acc.replace(new RegExp(`{${key}}`, "g"), value.toString()),
        translationText,
      );
    },
    [translationQuery.data],
  );

  const contextValue = useMemo(
    () => ({ getTranslation, language }),
    [getTranslation, language],
  );

  if (translationQuery.isLoading && loadingComponent) {
    return <>{loadingComponent}</>;
  }

  return (
    <I18nContext.Provider value={contextValue}>{children}</I18nContext.Provider>
  );
};
