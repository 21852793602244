import React, { forwardRef, useEffect, useRef, useState } from "react";
import {
  WhmCard,
  WhmCardContent,
  WhmFade,
  WhmGrid,
} from "@securitize/reactjs-whm";
import NameStep from "./NameStep";
import PhoneStep from "./PhoneStep";
import AccountTypeStep from "./AccountTypeStep";
import EntityInfoStep from "./EntityInfoStep";
import { useFormikContext } from "formik";
import { FormType } from "./types";

const StepContainer = forwardRef<
  HTMLDivElement,
  {
    isOpen: boolean;
    children: React.ReactNode;
    onEnter: (here: HTMLDivElement) => void;
  }
>(({ isOpen, children }, ref) => {
  return (
    <WhmFade in={isOpen} timeout={isOpen ? 1500 : 500}>
      <WhmCardContent
        ref={ref}
        sx={{ position: "absolute", width: "480px", padding: 6 }}
      >
        {children}
      </WhmCardContent>
    </WhmFade>
  );
});

export default function DesktopForm({ step }: { step: string }) {
  const [height, setHeight] = useState(400);
  const containerRef = useRef(null);
  const nameRef = useRef<HTMLDivElement>(null);
  const phoneRef = useRef<HTMLDivElement>(null);
  const accountTypeRef = useRef<HTMLDivElement>(null);
  const entityInfoRef = useRef<HTMLDivElement>(null);
  const formik = useFormikContext<FormType>();

  const resizeOnChange = () => {
    if (step === "name") {
      if (nameRef.current) {
        setHeight(nameRef.current.clientHeight);
      }
    }

    if (step === "phone") {
      if (phoneRef.current) {
        setHeight(phoneRef.current.clientHeight);
      }
    }

    if (step === "accountType") {
      if (accountTypeRef.current) {
        setHeight(accountTypeRef.current.clientHeight);
      }
    }

    if (step === "entityInfo") {
      if (entityInfoRef.current) {
        setHeight(entityInfoRef.current.clientHeight);
      }
    }
  };

  useEffect(resizeOnChange, [formik, step]); // Just try to resize whenever Formik changes: e.g., errors.

  return (
    <WhmGrid
      container
      sx={{
        width: "100vw",
        height: "100vh",
        position: "absolute",
        top: "0",
        left: "0",
      }}
      direction="row"
      alignContent="center"
      justifyContent="center"
    >
      <WhmGrid item>
        <WhmCard
          ref={containerRef}
          sx={{
            width: "480px",
            height: `${height}px`,
            overflow: "hidden",
            position: "relative",
            transition: "height 0.5s",
          }}
          variant="outlined"
        >
          <StepContainer
            isOpen={step === "name"}
            ref={nameRef}
            onEnter={(here) => {
              setHeight(here.clientHeight);
            }}
          >
            <NameStep />
          </StepContainer>

          <StepContainer
            isOpen={step === "phone"}
            ref={phoneRef}
            onEnter={(here) => {
              setHeight(here.clientHeight);
            }}
          >
            <PhoneStep />
          </StepContainer>

          <StepContainer
            isOpen={step === "accountType"}
            ref={accountTypeRef}
            onEnter={(here) => {
              setHeight(here.clientHeight);
            }}
          >
            <AccountTypeStep />
          </StepContainer>

          <StepContainer
            isOpen={step === "entityInfo"}
            ref={entityInfoRef}
            onEnter={(here) => {
              setHeight(here.clientHeight);
            }}
          >
            <EntityInfoStep />
          </StepContainer>
        </WhmCard>
      </WhmGrid>
    </WhmGrid>
  );
}
